import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Connection,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  LAMPORTS_PER_SOL,
} from '@solana/web3.js';
import { Program, AnchorProvider, web3 } from '@project-serum/anchor';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
} from '@solana/spl-token';
import idl from '../idl/vote_nft_program.json';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, updateDoc, increment } from 'firebase/firestore'; // Firestore functions
import { db } from '../Firebase'; // Import Firestore instance

const ButtonContainer = styled.button`
  background-color: ${(props) => (props.disabled ? '#6c757d' : '#28a745')};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: 20px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#6c757d' : '#218838')};
  }
`;

const MintButton = ({ selectedColor, setIsMinting, onMintSuccess }) => {
  const wallet = useWallet();
  const [loading, setLoading] = useState(false);
  const [network, setNetwork] = useState('');
  const navigate = useNavigate();

  // Update NETWORK to use your QuickNode RPC HTTP endpoint
  const NETWORK = 'https://fittest-sparkling-glade.solana-mainnet.quiknode.pro/f48a88ce0166386f4e2c34d890386493f2554f85';
  const connection = new Connection(NETWORK, 'confirmed');

  useEffect(() => {
    const checkNetwork = async () => {
      if (wallet.connected) {
        try {
          const networkVersion = await connection.getVersion();
          setNetwork(networkVersion['solana-core']);
          console.log('Connected to network:', networkVersion['solana-core']);
        } catch (error) {
          console.error('Error checking network:', error);
        }
      }
    };

    checkNetwork();
  }, [wallet.connected]);

  const programId = new PublicKey('9rPtukBTWwTun42zMkpjG12e6VUBmcNi3abx6ZXHoyM4');
  const TOKEN_METADATA_PROGRAM_ID = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');
  const TREASURY_ACCOUNT = new PublicKey('8diw4aVkwQ8HoCj2UHatuZ9si9caQUK5GxrJSV6AAyiv');

  const updateMintStats = async (selectedColor, mintAddress) => {
    try {
      const statRef = doc(db, 'stats', selectedColor); // Reference to the specific color in 'stats' collection
      const newMintRef = doc(db, 'mints', mintAddress); // Reference to store the mint details in 'mints' collection
  
      await setDoc(statRef, { count: increment(1) }, { merge: true });
      await setDoc(newMintRef, { color: selectedColor, address: mintAddress });
  
      console.log(`Updated stats for ${selectedColor}`);
    } catch (error) {
      console.error('Error updating Firestore:', error);
    }
  };

  const handleMint = async () => {
    if (!selectedColor || !wallet.connected || !wallet.publicKey) {
      alert('Please connect your wallet and select a color!');
      setIsMinting(false);
      return;
    }

    console.log('Connected wallet address:', wallet.publicKey.toString());
    console.log('Current network:', network);

    setIsMinting(true);
    setLoading(true);

    try {
      const provider = new AnchorProvider(connection, wallet, {
        preflightCommitment: 'processed',
      });
      const program = new Program(idl, programId, provider);

      let metadataUri, party;
      if (selectedColor === 'Red') {
        metadataUri = 'https://ipfs.io/ipfs/QmRMnK7ijLTEt9B6iJdM94oVPzXtNkDJA399C6TqkWUDmt';
        party = 'Republican';
      } else if (selectedColor === 'Blue') {
        metadataUri = 'https://ipfs.io/ipfs/QmTKNN5BWxgFdXKsMUFS96Ahqek5c1Et59TaJmpQR6zHQs';
        party = 'Democrat';
      } else if (selectedColor === 'Gray') {
        metadataUri = 'https://ipfs.io/ipfs/QmQfAgMp43mFLmxgxtZnfEz3FLMy3rVYNLAMNHaTMgcWd1';
        party = 'Independent';
      }

      const mint = web3.Keypair.generate();
      console.log('Mint Account:', mint.publicKey.toString());

      const tokenATA = await getAssociatedTokenAddress(
        mint.publicKey,
        wallet.publicKey
      );

      const [metadataAddress] = await PublicKey.findProgramAddress(
        [
          Buffer.from('metadata'),
          TOKEN_METADATA_PROGRAM_ID.toBuffer(),
          mint.publicKey.toBuffer(),
        ],
        TOKEN_METADATA_PROGRAM_ID
      );

      const [masterEditionAddress] = await PublicKey.findProgramAddress(
        [
          Buffer.from('metadata'),
          TOKEN_METADATA_PROGRAM_ID.toBuffer(),
          mint.publicKey.toBuffer(),
          Buffer.from('edition'),
        ],
        TOKEN_METADATA_PROGRAM_ID
      );

      const tx = await program.methods
        .mintVoteNft(
          selectedColor,
          party,
          metadataUri,
          `${selectedColor} Vote NFT`
        )
        .accounts({
          payer: wallet.publicKey,
          treasury: TREASURY_ACCOUNT,
          mint: mint.publicKey,
          tokenAccount: tokenATA,
          metadata: metadataAddress,
          masterEdition: masterEditionAddress,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .signers([mint])
        .rpc();

      console.log('Transaction successful:', tx);

      // Firestore: Update stats and mint address
      await updateMintStats(selectedColor, mint.publicKey.toString());

      onMintSuccess();
      navigate('/success', { state: { selectedColor } });

    } catch (error) {
      console.error('Error minting NFT:', error);
      if (error.logs) {
        console.error('Transaction logs:', error.logs);
      }
      alert(`Minting failed. Error: ${error.message}`);
    } finally {
      setIsMinting(false);
      setLoading(false);
    }
  };

  return (
    <ButtonContainer
      onClick={handleMint}
      disabled={!selectedColor || loading}
    >
      {loading
        ? 'Minting...'
        : selectedColor
        ? `Mint Your ${selectedColor} NFT (~ 0.12 SOL)`
        : 'Select a Color to Mint'}
    </ButtonContainer>
  );
};

export default MintButton;
