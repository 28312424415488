import React from 'react';
import styled from 'styled-components';

// Styled Components
const SquareContainer = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

    @media(max-width:768px){
    width: 200px;
  height: 100px;
  }
`;

const PartyLabel = styled.p`
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;

  @media(max-width:768px){
    font-size:0.85rem;
  }
`;

const ColorSquare = ({ color, partyName, onClick }) => {
  return (
    <SquareContainer onClick={onClick} color={color}>
      <PartyLabel>{partyName}</PartyLabel>
    </SquareContainer>
  );
};

export default ColorSquare;