import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MintSuccessPage from './pages/MintSuccessPage';
import StatsPage from './pages/StatsPage';
import { WalletConnectProvider } from './components/WalletConnectProvider';
import New from './components/New';

function App() {
  return (
    <WalletConnectProvider> {/* Wrap everything to ensure wallet is available throughout the app */}
      <Router>
        <Routes>
          {/* Main home page where users select a color and mint their NFTs */}
          <Route path="/" element={<New />} />

          {/* Page shown after successful minting */}
          <Route path="/success" element={<MintSuccessPage />} />

          {/* Page for displaying stats on minted NFTs */}
          <Route path="/stats" element={<StatsPage />} />

          {/* Page for displaying stats on minted NFTs */}
          <Route path="/new" element={<New />} />
        </Routes>
      </Router>
    </WalletConnectProvider>
  );
}

export default App;
