// src/components/New.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CircleMenu from './CircleMenu';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase';
import MintButton from './MintButton';
import Header from './Header';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f7f7f7;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StatisticsSection = styled.div`
  margin: 40px auto;
  padding: 40px;
  border-radius: 20px;
  max-width: 900px;
  text-align: center;
`;

const StatisticsTitle = styled.h2`
  font-size: 1.5rem;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const StatGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
`;

const StatCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
`;

const PartyDetails = styled.div`
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PartyName = styled.h3`
  font-size: 1.2rem;
  color: black;
  text-align: left;
  font-weight: bold;
`;

const PartyCount = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: black;
  padding-bottom: 20px;
`;

const ProgressBarWrapper = styled.div`
  flex: 1;
  padding-left: 20px;
`;

const ProgressBarBackground = styled.div`
  height: 8px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) => props.color};
  position: absolute;
`;

const CallToAction = styled.div`
  margin-top: 50px;
  padding: 20px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.txColor};
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: bold;
`;

const getContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const partyColors = {
  Republican: '#d72638',
  Democratic: '#005eb8',
  Constitution: '#8b4513',
  Libertarian: '#ebaa04',
  Green: '#228b22',
  'Justice For All': '#800080',
};

const New = () => {
  const [selectedColor, setSelectedColor] = useState('');
  const [isMinting, setIsMinting] = useState(false);
  const [awaitingApproval, setAwaitingApproval] = useState(false);
  const [stats, setStats] = useState({
    Republican: 0,
    Democratic: 0,
    Constitution: 0,
    Libertarian: 0,
    Green: 0,
    'Justice For All': 0,
  });
  const [mostVotes, setMostVotes] = useState({ party: '', count: 0 });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const parties = Object.keys(partyColors);
        const newStats = {};
        for (const party of parties) {
          const docRef = doc(db, 'stats', party);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            newStats[party] = docSnap.data().count;
          } else {
            newStats[party] = 0;
          }
        }
        setStats(newStats);
        updateMostVotes(newStats);
      } catch (error) {
        console.error('Error fetching stats from Firestore:', error);
      }
    };

    fetchStats();
  }, []);

  const updateMostVotes = (currentStats) => {
    const maxVotes = Math.max(...Object.values(currentStats));
    const partyWithMostVotes = Object.keys(currentStats).find(
      (party) => currentStats[party] === maxVotes
    );
    setMostVotes({ party: partyWithMostVotes, count: maxVotes });
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleMintSuccess = () => {
    setStats((prevStats) => {
      const newStats = {
        ...prevStats,
        [selectedColor]: prevStats[selectedColor] + 1,
      };
      updateMostVotes(newStats);
      return newStats;
    });
  };

  return (
    <PageContainer>
      <Header />
      <Content>
        <CircleMenu
          isMinting={isMinting}
          awaitingApproval={awaitingApproval}
          onColorSelect={handleColorSelect}
        />

        <MintButton
          selectedColor={selectedColor}
          isMinting={isMinting}
          setIsMinting={setIsMinting}
          awaitingApproval={awaitingApproval}
          setAwaitingApproval={setAwaitingApproval}
          onMintSuccess={handleMintSuccess}
        />
      </Content>

      <StatisticsSection>
        <StatisticsTitle>Count</StatisticsTitle>
        <StatGrid>
          {stats &&
            Object.entries(stats)
              .sort((a, b) => b[1] - a[1])
              .map(([party, count]) => {
                const bgColor = partyColors[party] || '#ccc';
                const textColor = getContrastColor(bgColor);
                const mostVotesCount = mostVotes?.count || 1;
                const percentage = (count / mostVotesCount) * 100;

                return (
                  <StatCard key={party} color={bgColor}>
                    <PartyDetails>
                      <PartyName>{party}</PartyName>
                      <PartyCount>{count}</PartyCount>
                    </PartyDetails>
                    <ProgressBarWrapper>
                      <ProgressBarBackground>
                        <ProgressBarFill
                          color={bgColor}
                          percentage={percentage}
                        />
                      </ProgressBarBackground>
                    </ProgressBarWrapper>
                  </StatCard>
                );
              })}
        </StatGrid>
        {mostVotes && mostVotes.party && (
          <CallToAction
            bgColor={partyColors[mostVotes.party] || '#ccc'}
            txColor={getContrastColor(partyColors[mostVotes.party] || '#ccc')}
          >
            Leading Party: {mostVotes.party} with {mostVotes.count} votes
          </CallToAction>
        )}
      </StatisticsSection>
    </PageContainer>
  );
};

export default New;
