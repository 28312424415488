// src/components/MintButton.js

import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Connection,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
} from '@solana/web3.js';
import { Program, AnchorProvider, web3 } from '@project-serum/anchor';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
} from '@solana/spl-token';
import idl from '../idl/vote_nft_program.json';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, increment } from 'firebase/firestore';
import { db } from '../Firebase';

const ButtonContainer = styled.button`
  background-color: ${(props) => (props.disabled ? '#D3D3D3' : '#333')};
  color: white;
  font-family: 'Afacad Flux', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  border: none;
  border-radius: 5px;
  padding: 20px 40px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: 20px;
  margin-bottom: 70px;
  position: relative;
  display: inline-block;
  transition: top 0.2s ease, background-color 0.2s ease;
  text-shadow: 0px 1px 0px #000;
  box-shadow: inset 0 1px 0 ${(props) => (props.disabled ? '#E0E0E0' : '#CCCCCC')},
    0 10px 0 ${(props) => (props.disabled ? '#A0A0A0' : '#555555')};

  &:hover {
    background-color: ${(props) => (props.disabled ? '#D3D3D3' : '#444')};
  }

  &:active {
    top: 10px;
    background-color: ${(props) => (props.disabled ? '#D3D3D3' : '#444')};
    box-shadow: inset 0 1px 0 ${(props) => (props.disabled ? '#E0E0E0' : '#CCCCCC')},
      inset 0 -3px 0 ${(props) => (props.disabled ? '#A0A0A0' : '#555555')};
  }

  &:after {
    content: '';
    height: 100%;
    width: 100%;
    padding: 4px;
    position: absolute;
    bottom: -15px;
    left: -4px;
    z-index: -1;
    background-color: ${(props) => (props.disabled ? '#A0A0A0' : '#2B1800')};
    border-radius: 5px;
  }
`;

const MintButton = ({
  selectedColor,
  isMinting,
  setIsMinting,
  awaitingApproval,
  setAwaitingApproval,
  onMintSuccess,
}) => {
  const wallet = useWallet();
  const navigate = useNavigate();

  const NETWORK =
    'https://fittest-sparkling-glade.solana-mainnet.quiknode.pro/f48a88ce0166386f4e2c34d890386493f2554f85';
  const connection = new Connection(NETWORK, 'confirmed');

  const programId = new PublicKey('3mmqSs597n3TAwzYZeUo5e2PL7cQwyPrgPnL2d98Fj8U');
  const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
    'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'
  );
  const TREASURY_WALLET_ADDRESS = new PublicKey(
    '8puCKUDUoXVsqbpodA9fN4p17q12oF1f4sREptUMBqsr'
  );

  const updateMintStats = async (selectedColor, mintAddress) => {
    try {
      const statRef = doc(db, 'stats', selectedColor);
      const newMintRef = doc(db, 'mints', mintAddress);

      await setDoc(
        statRef,
        { count: increment(1) },
        { merge: true }
      );
      await setDoc(newMintRef, {
        color: selectedColor,
        address: mintAddress,
      });

      console.log(`Updated stats for ${selectedColor}`);
    } catch (error) {
      console.error('Error updating Firestore:', error);
    }
  };

  const handleMint = async () => {
    if (!selectedColor || !wallet.connected || !wallet.publicKey) {
      alert('Please connect your wallet and select a party!');
      return;
    }

    setAwaitingApproval(true);

    try {
      const provider = new AnchorProvider(connection, wallet, {
        preflightCommitment: 'processed',
      });
      const program = new Program(idl, programId, provider);

      let metadataUri, party;
      switch (selectedColor) {
        case 'Republican':
          metadataUri =
            'https://ipfs.io/ipfs/QmUJ7tg3MNg9DVredQq5xJ4VUCWfYJRRr44Z7hxodKfrm6';
          party = 'Republican';
          break;
        case 'Democratic':
          metadataUri =
            'https://ipfs.io/ipfs/QmYtgBxya4LdLAwb2oGiuNLRiGmtq4AagE6RtvHVpiRTCc';
          party = 'Democratic';
          break;
        case 'Libertarian':
          metadataUri =
            'https://ipfs.io/ipfs/QmPRmvqXQERQRLfEE7X2J2ebxxWBY52dLsHucBo5dC3s22';
          party = 'Libertarian';
          break;
        case 'Justice For All':
          metadataUri =
            'https://ipfs.io/ipfs/QmSnF4vVQZXUUsYbHMPha9beCN57b6ZJc283fyKvWMtWvm';
          party = 'Justice For All';
          break;
        case 'Constitution':
          metadataUri =
            'https://ipfs.io/ipfs/QmQfB8NrxBNTWpudqKwDVToJm9k7JJMd6Q8ArBVSQMzkex';
          party = 'Constitution';
          break;
        case 'Green':
          metadataUri =
            'https://ipfs.io/ipfs/QmWhcdVuYE1ncMFwBfBVrtHnhbiJmWwL2UqhcJpebdekWb';
          party = 'Green';
          break;
        default:
          throw new Error('Invalid party selected');
      }

      const mint = web3.Keypair.generate();
      console.log('Mint Account:', mint.publicKey.toString());

      const tokenATA = await getAssociatedTokenAddress(
        mint.publicKey,
        wallet.publicKey
      );

      const [metadataAddress] = await PublicKey.findProgramAddress(
        [
          Buffer.from('metadata'),
          TOKEN_METADATA_PROGRAM_ID.toBuffer(),
          mint.publicKey.toBuffer(),
        ],
        TOKEN_METADATA_PROGRAM_ID
      );

      const [masterEditionAddress] = await PublicKey.findProgramAddress(
        [
          Buffer.from('metadata'),
          TOKEN_METADATA_PROGRAM_ID.toBuffer(),
          mint.publicKey.toBuffer(),
          Buffer.from('edition'),
        ],
        TOKEN_METADATA_PROGRAM_ID
      );

      setAwaitingApproval(false);
      setIsMinting(true);

      const tx = await program.methods
        .mintVoteNft(
          selectedColor,
          party,
          metadataUri,
          `${selectedColor} Vote NFT`
        )
        .accounts({
          payer: wallet.publicKey,
          treasury: TREASURY_WALLET_ADDRESS,
          mint: mint.publicKey,
          tokenAccount: tokenATA,
          metadata: metadataAddress,
          masterEdition: masterEditionAddress,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        })
        .signers([mint])
        .rpc();

      console.log('Transaction successful:', tx);

      await updateMintStats(selectedColor, mint.publicKey.toString());

      onMintSuccess();
      navigate('/success', { state: { selectedColor } });
    } catch (error) {
      console.error('Error minting NFT:', error);
      if (error.logs) {
        console.error('Transaction logs:', error.logs);
      }
      alert(`Minting failed. Error: ${error.message}`);
    } finally {
      setIsMinting(false);
      setAwaitingApproval(false);
    }
  };

  return (
    <ButtonContainer
      onClick={handleMint}
      disabled={!selectedColor || awaitingApproval || isMinting}
    >
      {awaitingApproval
        ? 'Awaiting Wallet Approval...'
        : isMinting
        ? 'Minting (please wait)...'
        : selectedColor
        ? `Mint Your ${selectedColor} NFT (~ 0.12 SOL)`
        : 'Select a Party to Mint'}
    </ButtonContainer>
  );
};

export default MintButton;
