import React from 'react';
import styled from 'styled-components';

// Styled Components
const FooterContainer = styled.footer`
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
  border-top: 2px solid #e9ecef;
  margin-top: 40px;
`;

const FooterLinks = styled.div`
  margin-bottom: 10px;
`;

const FooterLink = styled.a`
  margin: 0 15px;
  color: #007bff;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const FooterText = styled.p`
  color: #6c757d;
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      {/* <FooterLinks>
        <FooterLink href="/terms">Terms of Service</FooterLink>
        <FooterLink href="/privacy">Privacy Policy</FooterLink>
        <FooterLink href="/contact">Contact Us</FooterLink>
      </FooterLinks> */}
      <FooterText>© 2024 Mint A Vote</FooterText>
    </FooterContainer>
  );
};

export default Footer;