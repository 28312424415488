// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBxvdfC13dx8G44l3GwcxbqpGlaHXs-yJY",
    authDomain: "mint-a-vote.firebaseapp.com",
    projectId: "mint-a-vote",
    storageBucket: "mint-a-vote.appspot.com",
    messagingSenderId: "789436942741",
    appId: "1:789436942741:web:545b8230d3e414989dce9c",
    measurementId: "G-6VSL3P5LMD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

// Export the services
export { db, auth, analytics, storage };