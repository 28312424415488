import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ConnectWalletButton } from './WalletConnectProvider'; // Import the wallet button from your provider

// Styled Components
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
//   background-color: #f8f9fa;
//   border-bottom: 2px solid #e9ecef;
`;

const Logo = styled.h1`
  font-size: 1.8rem;
    font-family: "Roboto", sans-serif;

  color: #333;
  margin: 0;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`

const Div = styled.div`
  display:flex;
  align-items:center;
`

const StyledLink2 = styled(Link)`
    text-decoration: none;
    color:black;
    margin-left:20px;

     &:hover {
     font-weight:600;
  }
`

const Header = () => {
  return (
    <HeaderContainer>
      <Div>
      <StyledLink to='/'>
        <Logo>Mint A Vote</Logo>
      </StyledLink>
      {/* <StyledLink2 to='/stats'>
        Stats
      </StyledLink2> */}
      </Div>
      {/* Connect Wallet Button */}
      <ConnectWalletButton /> {/* Replaced with Solana wallet connect button */}
    </HeaderContainer>
  );
};

export default Header;
