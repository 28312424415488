import React from 'react';
import styled from 'styled-components';

// Styled Components
const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StatItem = styled.div`
  margin: 0 20px;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size:0.8rem;
    font-weight: 400;
  }
`;

const ColorBox = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  margin-right: 10px;
`;

const Statistics = ({ redCount, blueCount, grayCount }) => {
  const total = redCount + blueCount + grayCount;
  const redPercentage = ((redCount / total) * 100).toFixed(2);
  const bluePercentage = ((blueCount / total) * 100).toFixed(2);
  const grayPercentage = ((grayCount / total) * 100).toFixed(2);

  return (
    <StatsContainer>
      <StatItem>
        <ColorBox color="#ff0000" /> Red: {redPercentage}%
      </StatItem>
      <StatItem>
        <ColorBox color="#0000ff" /> Blue: {bluePercentage}%
      </StatItem>
      <StatItem>
        <ColorBox color="#808080" /> Gray: {grayPercentage}%
      </StatItem>
    </StatsContainer>
  );
};

export default Statistics;