import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Statistics from '../components/Statistics';
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../Firebase'; // Import Firestore instance

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color:#f7f7f7;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const DetailedStats = styled.div`
  margin-top: 40px;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StatItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  width: 100%;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 10px;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.span`
  color: #007bff;
`;

const StatsPage = () => {
  const [redCount, setRedCount] = useState(0);
  const [blueCount, setBlueCount] = useState(0);
  const [grayCount, setGrayCount] = useState(0);

  useEffect(() => {
    // Fetching the stats from Firestore
    const fetchStats = async () => {
      try {
        const redDoc = await getDoc(doc(db, 'stats', 'Red'));
        const blueDoc = await getDoc(doc(db, 'stats', 'Blue'));
        const grayDoc = await getDoc(doc(db, 'stats', 'Gray'));

        if (redDoc.exists()) setRedCount(redDoc.data().count);
        if (blueDoc.exists()) setBlueCount(blueDoc.data().count);
        if (grayDoc.exists()) setGrayCount(grayDoc.data().count);
      } catch (error) {
        console.error('Error fetching stats from Firestore:', error);
      }
    };

    fetchStats();
  }, []);

  return (
    <PageContainer>
      <Header />
      <Content>
        <h1>Minted NFT Statistics</h1>
        <Statistics redCount={redCount} blueCount={blueCount} grayCount={grayCount} />
        <DetailedStats>
          <StatItem>
            <Label>Total NFTs Minted:</Label>
            <Value>{redCount + blueCount + grayCount}</Value>
          </StatItem>
          <StatItem>
            <Label>Red NFTs:</Label>
            <Value>{redCount}</Value>
          </StatItem>
          <StatItem>
            <Label>Blue NFTs:</Label>
            <Value>{blueCount}</Value>
          </StatItem>
          <StatItem>
            <Label>Gray NFTs:</Label>
            <Value>{grayCount}</Value>
          </StatItem>
        </DetailedStats>
      </Content>
      <Footer />
    </PageContainer>
  );
};

export default StatsPage;
