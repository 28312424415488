import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';

// Import local PNG images
import redImage from '../assets/red.png';
import blueImage from '../assets/blue.png';
import purpleImage from '../assets/purple.png';
import greenImage from '../assets/green.png';
import yellowImage from '../assets/yellow.png';
import constitutionImage from '../assets/constitution.png';

// Mapping color to image imports
const colorToImageMap = {
  Republican: redImage,
  Democratic: blueImage,
  Libertarian: yellowImage,
  'Justice For All': purpleImage,
  Constitution: constitutionImage,
  Green: greenImage,
};

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f7f7f7;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const Message = styled.p`
  font-size: 1.5rem;
  color: #333;
  text-align: center;
`;

const ColorText = styled.span`
  font-weight: bold;
  color: ${props => props.color};
  text-transform: capitalize;
`;

const Image = styled.img`
  margin-top: 20px;
  width: 300px;
  height: 300px;
  object-fit: contain;
`;

const ShareButton = styled.button`
  align-items: center;
  background-color: #1DA1F2;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  padding: 0 25px;
  margin-top: 20px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #0d95e8;
  }
`;

const shareOnTwitter = (selectedColor) => {
  const tweetText = `I just minted a ${selectedColor} NFT! Show your support and mint yours at mintavote.com #MintYourVote`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
  window.open(twitterUrl, '_blank');
};

const MintSuccessPage = () => {
  const location = useLocation();
  const { selectedColor } = location.state || {};

  const imageUrl = colorToImageMap[selectedColor];

  return (
    <PageContainer>
      <Header />
      <Content>
        <MessageContainer>
          <Message>
            Congratulations! You successfully minted your <ColorText color={selectedColor}>{selectedColor}</ColorText> NFT!
          </Message>
          {imageUrl && <Image src={imageUrl} alt={`${selectedColor} NFT`} />}
          <ShareButton onClick={() => shareOnTwitter(selectedColor)}>
            Share on Twitter
          </ShareButton>
        </MessageContainer>
      </Content>
      <Footer />
    </PageContainer>
  );
};

export default MintSuccessPage;