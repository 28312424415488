import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import ColorSquare from '../components/ColorSquare';
import MintButton from '../components/MintButtonOld';
import Statistics from '../components/Statistics';
import Footer from '../components/Footer';
import LoadingSpinner from '../components/LoadingSpinner';
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../Firebase'; // Import Firestore instance

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color:#f7f7f7;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ColorSelection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MintSection = styled.div`
  margin-bottom: 30px;
`;

const HomePage = () => {
  const [selectedColor, setSelectedColor] = useState('');
  const [isMinting, setIsMinting] = useState(false);
  const [redCount, setRedCount] = useState(0);
  const [blueCount, setBlueCount] = useState(0);
  const [grayCount, setGrayCount] = useState(0);

  useEffect(() => {
    // Fetch initial counts from Firestore
    const fetchStats = async () => {
      try {
        const redDoc = await getDoc(doc(db, 'stats', 'Red'));
        const blueDoc = await getDoc(doc(db, 'stats', 'Blue'));
        const grayDoc = await getDoc(doc(db, 'stats', 'Gray'));

        if (redDoc.exists()) setRedCount(redDoc.data().count);
        if (blueDoc.exists()) setBlueCount(blueDoc.data().count);
        if (grayDoc.exists()) setGrayCount(grayDoc.data().count);
      } catch (error) {
        console.error('Error fetching initial stats from Firestore:', error);
      }
    };

    fetchStats();
  }, []);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const handleMintSuccess = () => {
    // Update statistics locally after successful minting
    if (selectedColor === 'Red') setRedCount(redCount + 1);
    else if (selectedColor === 'Blue') setBlueCount(blueCount + 1);
    else if (selectedColor === 'Gray') setGrayCount(grayCount + 1);
  };

  return (
    <PageContainer>
      <Header />
      <Content>
        <ColorSelection>
          <ColorSquare color="#e9141d" partyName="Republican" onClick={() => handleColorSelect('Red')} />
          <ColorSquare color="#00aef3" partyName="Democratic" onClick={() => handleColorSelect('Blue')} />
          <ColorSquare color="#7f7f7f" partyName="Independent" onClick={() => handleColorSelect('Gray')} />
        </ColorSelection>
        <MintSection>
          {isMinting ? (
            <LoadingSpinner />
          ) : (
            <MintButton
              selectedColor={selectedColor}
              setIsMinting={setIsMinting}
              onMintSuccess={handleMintSuccess}
            />
          )}
        </MintSection>
        <Statistics redCount={redCount} blueCount={blueCount} grayCount={grayCount} />
      </Content>
      <Footer />
    </PageContainer>
  );
};

export default HomePage;
