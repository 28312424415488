import React, { useEffect } from 'react';
import { WalletProvider, ConnectionProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { SolletWalletAdapter } from '@solana/wallet-adapter-sollet';
import { LedgerWalletAdapter } from '@solana/wallet-adapter-ledger';
import { TorusWalletAdapter } from '@solana/wallet-adapter-torus';
import { CoinbaseWalletAdapter } from '@solana/wallet-adapter-coinbase';
import { MathWalletAdapter } from '@solana/wallet-adapter-mathwallet';
import '@solana/wallet-adapter-react-ui/styles.css';

// Define your wallet adapters
const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
    new SolletWalletAdapter({ network: 'devnet' }), // Ensure proper network usage
    new LedgerWalletAdapter(),
    new TorusWalletAdapter(),
    new CoinbaseWalletAdapter(),
    new MathWalletAdapter(),
];

const WalletConnectProvider = ({ children }) => {
    const endpoint = clusterApiUrl('devnet'); // You can switch to 'mainnet-beta' or 'testnet' as needed

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export const ConnectWalletButton = () => {
    const wallet = useWallet();

    useEffect(() => {
        if (wallet.connected && wallet.publicKey) {
            const walletAddress = wallet.publicKey.toString();
            console.log('Connected Wallet Address:', walletAddress);
            // You can implement any further logic here, such as connecting to your backend
        }
    }, [wallet.connected, wallet.publicKey]);

    return (
        <WalletMultiButton 
            style={{
                marginTop: '20px',
                marginBottom: '20px',
                marginRight: '20px',
                padding: '20px',
                position: 'fixed',
                top: '0',
                right: '0',
                color: 'white',
                backgroundColor: '#9945FF',
                borderRadius: '10px',
                zIndex: '1000',
                cursor: 'pointer',
                fontFamily: 'Micro 5, sans-serif',
            }}
        />
    );
};

export { WalletConnectProvider };
